import {
  faLongArrowAltRight,
  faAngleDown,
  faAngleUp,
  faTimes,
  faChevronRight,
  faSpinner,
  faChevronDown,
  faChevronUp
} from '@fortawesome/free-solid-svg-icons';

export const iconTypes = {
  arrowRight: faLongArrowAltRight,
  caretDown: faAngleDown,
  caretUp: faAngleUp,
  close: faTimes,
  chevronRight: faChevronRight,
  loading: faSpinner,
  chevronDown: faChevronDown,
  chevronUp: faChevronUp
};
